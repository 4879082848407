<template>
	<div :style="rechargeBg" style="justify-content: center;">
		<Header :title="$t('recharge.rechargePbt')"></Header>
		
		<div class="column-center-content main-content" style="margin-top: 54px;">
			
			<div :style="qrBg" @click="copyAddress">
				<vue-qr :text="topUpAddress" :size="180" :margin="10" style="width: 40%; height: 60%; margin-top: 20px;"></vue-qr>
				<div  style="width: 100%; padding: 20px 16px; color: #333333; font-size: 14px; text-align: center;">
					<label v-text="topUpAddress"></label>
				</div>
				<label style="color: #FF0000; font-size: 18px;">{{$t('home.copy')}}</label>
			</div>
			
			<div style="width: 100%; padding: 5px 16px; text-align: right; margin-top: 10px;">
				<a @click="showHashTip" style="font-size: 14px; color: #009245;">
					{{$t('tips.rechangeTip')}}
				</a>
			</div>
			
			<div style="padding: 10px 16px; color: #888888; font-size: 14px; text-align: justify;">
				<label>
					<span style="font-size: 16px;">{{$t('common.tipsTitle')}}：<br/></span>
					1. {{$t('recharge.pbt_tips1')}}<br/>
					2. {{$t('recharge.tips2')}}<br/>
					3. {{$t('recharge.tips3')}}<br/>
					4. {{$t('recharge.tips4')}}<br/><br/>
					<p style="color: #FF0000;">{{$t('recharge.tips6')}}</p>
					<p style="color: #FF0000;">{{$t('recharge.tips5')}}</p>
				</label>
			</div>
		</div>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import vueQr from 'vue-qr'
	import Header from '../../../components/NavHeader.vue'
	import { Toast, Indicator, MessageBox } from 'mint-ui';

	export default {
		name: 'Recharge',
		components: {
			Header,
			vueQr
		},
		data:() => ({
			rechargeBg : {
				height: "100vh",
				width: "100%",
				backgroundImage: "url(" + require("../../../assets/login/new-bg.png") + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "fixed",
				backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				overflow: "auto"
			},
			qrBg : {
				height: "340px",
				width: "100%",
				//backgroundImage: "url(" + require("../../assets/qr-bg.png") + ")",
				//backgroundRepeat: "no-repeat",
				//backgroundPosition: "fixed",
				//backgroundSize: "100% 100%",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			},
			topUpAddress: '',
			erc20Style: 'selected',
			arc20Style: 'unselected',
			netType: 'erc20'
		}),
		created() {
			MessageBox(this.$i18n.t("common.tips"), '为了配合AMBG生态链ARC20底层的USDT启用规范，所有会员的ERC20充值地址将会同时进行更换，并且启用全新的充值钱包地址。');
		},
		mounted() {
			var __this = this;
			this.requestAddress(__this.$data.netType);
		},
		watch: {
			netType(val) {
				// 切换充币网络
				this.requestAddress(val);
			}
		},
		methods: {
			
			requestAddress(type) {
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				let params = {
					type: type
				}				
				this.$request.address(params).then((data)=>{
					Indicator.close();
					this.$data.topUpAddress = data.withdrawAddress1;
				});
			},
			
			showHashTip() {
				MessageBox.prompt(this.$i18n.t("tips.hash_hint"), this.$i18n.t("common.tips")).then(({ value, action }) => {
					
					if(action === 'confirm') {
						// 确认操作
						let hash = value;
						let type = this.netType
						if(null == hash || '' == hash) {
							Toast({
								message: this.$i18n.t("tips.hash_hint"),
								position: 'bottom'
							});
							return;
						}
						// 将hash提交至后台						
						this.$request.question({
							hash : hash,
							type : type
						}).then(()=>{
							Toast({
								message: this.$i18n.t("tips.hash_tip"),
								position: 'bottom'
							});
						});
					}
				},() => {
					// 取消操作
				});
			},
			
			copyAddress() {
				var domUrl = document.createElement("input");
				domUrl.value = this.$data.topUpAddress;
				domUrl.id = "creatDom";
				document.body.appendChild(domUrl);
				// 选择对象
				domUrl.select(); 
				// 执行浏览器复制命令
				document.execCommand("Copy"); 
				let creatDom = document.getElementById("creatDom");
				creatDom.parentNode.removeChild(creatDom);
				Toast({
					message: 'Success',
					position: 'bottom'
				});
			},
			
			selectErc20() {
				this.$data.erc20Style = 'selected';
				this.$data.arc20Style = 'unselected';
				this.$data.netType = 'erc20';
			},
			
			selectArc20() {
				this.$data.erc20Style = 'unselected';
				this.$data.arc20Style = 'selected';	
				this.$data.netType = 'arc20';
			}
		}
	}

</script>

<style>
	.selected {
		width: 40%;
		height: 40px;
		margin: 20px 15px;
		background-color: #009245;
		color: #FFFFFF;
		border-radius: 20px;
	}
	
	.unselected {
		width: 40%;
		height: 40px;
		margin: 20px 15px;
		background-color: #FFFFFF;
		color: #009245;
		border-radius: 20px;
		border: 1px solid #009245;
	}
</style>
